import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Chip,
    Container,
    Grid,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import withMuiTheme from 'containers/hoc/withMuiTheme'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { useImgix } from 'core/services/imgix/useImgix'
import { Link } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import React from 'react'

interface Props {
    establishment: undefined
    establishments: Array<EstablishmentModel>
    withNotFoundMessage: boolean
}

const TopContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(6),
})) as typeof Container

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '1.8rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: theme.spacing(1, 1),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
    },
})) as typeof Typography

const SubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    textAlign: 'center',
})) as typeof Typography

const CategoryChip = styled(Chip)(({ theme }) => ({
    marginTop: theme.spacing(0.5) + ' !important',
    marginBottom: theme.spacing(0.5) + ' !important',
})) as typeof Chip

const EstablishmentsView: React.FC<Props> = ({ establishments, withNotFoundMessage }) => {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true })
    const isMobileScreen = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true })

    const { getImage } = useImgix()
    const columns = isMobileScreen ? 2 : 4
    const spacing = isMobileScreen ? 2 : 4
    const getImageSource = (picture?: string, dpr?: number) => {
        const image = picture ?? process.env.GATSBY_ASSETS_URL + '/media/establishment/default.jpg'
        let width = 1440
        if (isLargeScreen) {
            width = 1536
        } else {
            if (typeof window !== 'undefined' && document) {
                width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            }
        }
        const targetWidth = Math.round((width - spacing * 8 * columns) / columns)
        const targetHeight = Math.round((targetWidth * 3) / 4)
        return {
            height: targetHeight,
            image: getImage(image, { h: targetHeight, fit: 'crop', dpr }),
        }
    }

    return (
        <Box sx={{ bgcolor: 'primary.main', paddingBottom: 6 }}>
            <TopContainer maxWidth="xl">
                <Grid container spacing={spacing} alignItems={'center'} justifyContent={'center'}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: withNotFoundMessage ? 'block' : 'none',
                        }}
                    >
                        <Typography
                            sx={{ fontSize: '1.2rem', textAlign: 'center' }}
                            component={'h2'}
                            color={'secondary'}
                        >
                            <Trans i18nKey="notFound.message" />
                        </Typography>
                    </Grid>
                    {establishments.map((establishment, index) => {
                        const { height, image } = getImageSource(establishment.picture)
                        return (
                            <Grid item md={4} xs={12} key={'establishments' + index}>
                                <Link
                                    to={'/restaurant/' + establishment.uriKey + '.html'}
                                    onClick={() => {
                                        if (typeof window !== 'undefined') {
                                            window.scrollTo(0, 0)
                                        }
                                    }}
                                >
                                    <Card
                                        sx={{
                                            position: 'relative',
                                            bgcolor: 'primary.main',
                                        }}
                                    >
                                        <CardMedia
                                            sx={{ opacity: 0.5 }}
                                            component={'img'}
                                            src={image}
                                            height={height}
                                            srcSet={
                                                getImageSource(establishment.picture, 1).image +
                                                ' 1x, ' +
                                                getImageSource(establishment.picture, 2).image +
                                                ' 2x, ' +
                                                getImageSource(establishment.picture, 3).image +
                                                ' 3x'
                                            }
                                        />
                                        <CardContent
                                            sx={{
                                                position: 'absolute',
                                                inset: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                zIndex: 3,
                                            }}
                                        >
                                            <Grid container justifyContent="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    <Title component="h1">{establishment.name}</Title>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SubTitle component="h2">{establishment.type}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        sx={{ paddingTop: 4, maxHeight: 40 }}
                                                        flexWrap="wrap"
                                                    >
                                                        {establishment.foodType?.map((type, index) => (
                                                            <CategoryChip
                                                                key={'foodType' + index}
                                                                label={type}
                                                                variant="outlined"
                                                                color="secondary"
                                                            />
                                                        ))}
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
            </TopContainer>
        </Box>
    )
}

export default withMuiTheme(EstablishmentsView)
