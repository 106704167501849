import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { EstablishmentModel } from 'core/models/establishmentModel'
import NotFoundView from 'components/views/notFoundView'
import { Helmet } from 'gatsby-plugin-react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from 'core/store/reducers'
import { fetchEstablishmentByUri } from 'core/ressources/establishment/queries'
import { setEstablishment } from 'core/store/app/actions'
import Loader from 'components/common/loader'
import EstablishmentView from 'components/views/establishmentView'
import { RouteComponentProps } from '@reach/router'
import useHubSpot from 'core/services/hubspot/useHubSpot'

interface Props extends RouteComponentProps {
    id?: string
    pageContext: {
        establishments: Array<EstablishmentModel>
    }
    data: {
        site: {
            siteMetadata: {
                siteUrl: string
            }
        }
    }
}

const NotFound: React.FC<Props> = ({
    location,
    pageContext: { establishments },
    data: {
        site: {
            siteMetadata: { siteUrl },
        },
    },
    id,
}) => {
    const dispatch = useDispatch()
    const hubSpotFormInitialized = useHubSpot()
    const { establishment } = useSelector(({ app }: ApplicationState) => ({
        establishment: app.establishment,
    }))
    const [isLoading, setIsLoading] = useState(id !== undefined && establishment.id === -1)

    useEffect(() => {
        if (id && establishment.id === -1) {
            setIsLoading(true)
            fetchEstablishmentByUri(id.replace('.html', ''))
                .then((establishment) => {
                    dispatch(setEstablishment(establishment))
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [id, establishment.id, dispatch])

    if (isLoading) {
        return <Loader />
    } else if (establishment.id !== -1) {
        return (
            <EstablishmentView
                establishment={establishment}
                siteUrl={siteUrl}
                hubSpotFormInitialized={hubSpotFormInitialized}
            />
        )
    } else {
        return (
            <>
                <Helmet>
                    <base href={siteUrl} />
                </Helmet>
                <NotFoundView
                    establishments={establishments}
                    withNotFoundMessage={!(location?.pathname === '/')}
                />
            </>
        )
    }
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

export default NotFound
