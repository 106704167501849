import { Box, Grid, styled, Typography } from '@mui/material'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { Helmet, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import EstablishmentsView from './establishmentsView'
import Footer from './footer'

interface Props {
    establishments: Array<EstablishmentModel>
    withNotFoundMessage: boolean
}

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '4rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
    },
})) as typeof Typography

const SubTitle = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '18rem',
    position: 'absolute',
    inset: 0,
    opacity: 0.2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '10rem',
    },
})) as typeof Typography

const NotFoundView: React.FC<Props> = ({ establishments, withNotFoundMessage }) => {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <title>{withNotFoundMessage ? t('notFound.title') : t('notFound.home')}</title>
                <meta name="description" content={t('notFound.message')} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={t('common.siteName')} />
                <meta property="og:title" content={t('notFound.title')} />
                <meta property="og:description" content={t('notFound.message')} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: withNotFoundMessage ? 'block' : 'none',
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: 'background.default',
                            height: '30vh',
                            minHeight: 300,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Title component={'h1'} color="textSecondary">
                            <Trans i18nKey="notFound.title" />
                        </Title>
                        <SubTitle component={'h2'} color="textSecondary">
                            404
                        </SubTitle>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <EstablishmentsView
                        establishment={undefined}
                        establishments={establishments}
                        withNotFoundMessage={withNotFoundMessage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            </Grid>
        </>
    )
}

export default NotFoundView
